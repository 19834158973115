import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import '../sass/productores_salas.scss';
import '../sass/general.scss';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';
import { VisibleType } from 'react-animate-on-scroll';
import { getLangValue } from '../../api/config';
import { getEmailComercial, getTelefonoComercial } from '../../api/helpText';
import { FormattedMessage } from 'react-intl';
interface IconoProps {
    color: string;
}

const Icono = (props: IconoProps) => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 300.1 300" xmlSpace="preserve">
            <path d="M228.1,98.4c4.5,4.5,4.5,11.8,0,16.3l-89.6,89.6c-3.9,3.9-10,4.4-14.5,1.5c-0.6-0.4-1.2-0.9-1.8-1.5l-48.9-48.9
                c-4.5-4.5-4.5-11.8,0-16.3c4.5-4.5,11.8-4.5,16.3,0l40.8,40.8l81.5-81.5C216.3,93.9,223.6,93.9,228.1,98.4z M300.1,150
                c0,82.7-67.3,150-150,150c-82.7,0-150-67.3-150-150c0-82.7,67.3-150,150-150C232.8,0,300.1,67.3,300.1,150z M277,150
                c0-70-56.9-126.9-126.9-126.9C80.1,23.1,23.2,80,23.2,150c0,70,56.9,126.9,126.9,126.9C220.1,276.9,277,220,277,150z"
                style={{ fill: props.color }} />
        </svg>
    );
}
const ProductoresYSalas = () => {
    const [isFixedElementVisible, setFixedElementVisible] = useState(true);
    const [iniciarCounter, setiniciarCounter] = useState(false);
    const [urlBoleteria] = useState<string>(`${getLangValue("mi")}`);
    const [urlS3] = useState<string>(`${getLangValue("s3")}`);
    const [telefonoComercial, setTelefonoComercial] = useState<string>('');
    const [lang] = useState<string>(`${getLangValue("lang")}`);
    const [emailComercial, setEmailComercial] = useState<string>('');
    const [imgInfra] = useState<string>(`${getLangValue("about-us-img-infra")}`);
    const [imgContable] = useState<string>(`${getLangValue("about-us-img-contable")}`);
    const [imgCeo] = useState<string>(`${getLangValue("about-us-img-ceo")}`);
    const [imgHD] = useState<string>(`${getLangValue("about-us-img-help-desk")}`);
    const [imgHB] = useState<string>(`${getLangValue("about-us-img-help-buyers")}`);
    const [imgCA] = useState<string>(`${getLangValue("about-us-img-access-control")}`);
    const [productYSalasTitle] = useState<string>(`${getLangValue("product-salas-title")}`);


    const colbacIn = (visible: VisibleType) => {
        setiniciarCounter(true)
        return {}
    }

    const handleEnd = ({ pauseResume }: { pauseResume: () => void }) => {
        pauseResume();
    };

    useEffect(() => {

        let alternateAr = document.getElementById("alternate_ar") as HTMLLinkElement;
        alternateAr.href = "https://www.entradaweb.com.ar/productores-y-salas";

        let alternateEs = document.getElementById("alternate_es") as HTMLLinkElement;
        alternateEs.href = "https://www.entradaweb.es/productores-y-salas";

        let alternateDefault = document.getElementById("alternate_default") as HTMLLinkElement;
        alternateDefault.href = "https://www.entradaweb.com/productores-y-salas";

        document.title = productYSalasTitle;

        (async () => {
            const res = await getTelefonoComercial();
            setTelefonoComercial(res.text);
        })();

        (async () => {
            const res = await getEmailComercial();
            setEmailComercial(res.text);
        })();

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)

        const handleScroll = () => {
            const divDestino = document.getElementById('seccion_4');
            const fixedElement = document.getElementById('scroll-element');

            if (!divDestino || !fixedElement) return;

            const divDestinoTop = divDestino.getBoundingClientRect().top;
            const fixedElementHeight = fixedElement.clientHeight;
            const isFixedElementAboveDiv = divDestinoTop >= fixedElementHeight;

            setFixedElementVisible(isFixedElementAboveDiv);
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
            window.removeEventListener('scroll', handleScroll);
          };

    }, [])

    return (
        <Col className='text-start productores-y-salas'>
            <section id="seccion_1" className="back_1">
                <ScrollAnimation className='fadeIn' animateIn='fadeIn' animateOut='bounceOutLeft'>
                    <h1 className="que-es text_2"><FormattedMessage id="what_is_it_title" /></h1>
                </ScrollAnimation>
                <Row>
                    <Col xs={2} className='pe-0 pt-1'>
                        <ScrollAnimation className='fadeIn' animateIn='fadeIn' animateOut='bounceOutLeft'>
                            <Icono color='#380AB4' />
                        </ScrollAnimation>
                    </Col>
                    <Col xs={10}>
                        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                            <p className="text_3"><FormattedMessage id="what_is_it_paragraph_1" /></p>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <ScrollAnimation delay={500} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                    <p className='min-font-size text-justify'>
                        <FormattedMessage id="what_is_it_paragraph_2" />
                    </p>
                </ScrollAnimation>
                <ScrollAnimation delay={1000} animateIn='fadeIn' animateOut='bounceOutLeft'>
                    <h2 className="h1 text_2 ps-5 ms-2 pe-5">Simple y sencillo para que uses tu tiempo en lo que más importa</h2>
                </ScrollAnimation>
                <Row>
                    <Col xs={10}>
                        <ScrollAnimation delay={1200} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                            <p className='min-font-size text-end'>
                                Hacemos de la simplicidad una de nuestras metas. Te brindamos herramientas simples y eficientes, muy fáciles de entender y usar. Publicar tu evento en EntradaWeb, aunque nunca hayas usado nuestra plataforma, te llevará solo unos pocos minutos, ya sea que uses Autogestión o nuestra Mesa de Ayuda. Lo mismo para los compradores de tus entradas, en 2 minutos ya obtienen sus tickets.
                            </p>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={2}>
                        <ScrollAnimation delay={1200} animateIn='bounceInLeft' animateOut='bounceOutLeft'>
                            <Icono color='#380AB4' />
                        </ScrollAnimation>
                    </Col>
                </Row>
            </section>
            <section id="seccion_2" className="back_2">
                <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                    <h1 className="text_1">Ventajas únicas de trabajar con nosotros</h1>
                </ScrollAnimation>
                <Row>
                    <Col xs={3} className='d-flex flex-column align-items-center'>
                        <Icono color='#9a83d7' />
                        <div className='linea  mt-2'></div>
                    </Col>
                    <Col xs={9}>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_1_1"/></p>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_1_2"/></p>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_1_3"/></p>
                        </ScrollAnimation>
                        {lang === 'es_AR' && <>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_1_4"/></p>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_1_5"/></p>
                        </ScrollAnimation>
                        </>}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={9}>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_2_1" /></p>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_2_2" /></p>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'>Nuestra plataforma trabaja con entradas o tickets con QR estáticos o con QR dinámicos a través de nuestra app <a href='smart-ticket' className="link-smart-link">Entradaweb QR y Smart Ticket.</a></p>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_2_3" /></p>
                        </ScrollAnimation>
                        {lang === 'es_AR' && <>
                        <ScrollAnimation delay={200} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='text_1'><FormattedMessage id="advantages_paragraph_2_4" /></p>
                        </ScrollAnimation>
                        </>}
                    </Col>
                    <Col xs={3} className='d-flex flex-column align-items-center'>
                        <Icono color='#9a83d7' />
                        <div className='linea d-flex flex-column align-items-center mt-2'></div>
                    </Col>
                </Row>

            </section>
            <section id="seccion_3" className="back_1 ps-4">
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <h1 className="text_2 text-end  pe-4 estamos-listos me-2"><FormattedMessage id="we_are_ready_title" /></h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                    <p className='min-font-size text-justify'>
                        <FormattedMessage id="we_are_ready_paragraph_1" />
                    </p>
                </ScrollAnimation>
                <Row>
                    {lang === 'es_AR' &&
                    <Col>
                        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                            <h2 className="text_3 text-end">¡Fiesta nacional de la vendimia x3 y contando!</h2>
                            <div className='ms-5 ps-2 mt-5 pt-2'><Icono color='#FF6600' /></div>
                        </ScrollAnimation>
                    </Col>}
                    <Col>
                        <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='min-font-size'>
                                <FormattedMessage id="we_are_ready_paragraph_2" />
                            </p>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                            <p className='min-font-size text-end'>
                                <FormattedMessage id="publish_our_platforms_paragraph" />
                            </p>
                        </ScrollAnimation>
                    </Col>
                    <Col>
                        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                            <h2 className="h1 text_2 mb-4"><FormattedMessage id="publish_our_platforms_title" /></h2>
                            <a href={`https://wa.me/${telefonoComercial}`} className="text_3 text-center">Mesa de ayuda<br/> a Productores</a>
                            <a href={urlBoleteria} className="text_3 text-center">Ir a <br/>Autogestión</a>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                            <p className='min-font-size text-end'><FormattedMessage id="ticket_office_paragraph_1" /></p>
                        </ScrollAnimation>
                    </Col>
                    <Col>
                        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                            <h2 className="h4 text_2" style={{"lineHeight":"40px"}}><FormattedMessage id="ticket_office_title" /></h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p className='min-font-size mt-4'><FormattedMessage id="ticket_office_paragraph_2" /></p>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </section>
            {lang === 'es_AR' ?
            <section id="seccion_3" className="back_3">
                <ScrollAnimation animateIn='fadeIn' afterAnimatedIn={colbacIn}>
                    <div className="icon_box">
                        <img src="/images/eventos-icon.svg" alt="" />
                        {iniciarCounter && <h3>+<CountUp end={20000} separator='.'/></h3>}
                        <p>Eventos Vendidos</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation delay={100} animateIn='fadeIn'>
                    <div className="icon_box">
                        <img src="images/entradas-icon.svg" alt="" />
                        {iniciarCounter && <h3>+
                            <CountUp
                                end={2}
                                decimal={'.'}
                                onEnd={handleEnd}
                            />{' '}MILLONES</h3>}
                        <p>Entradas Vendidas</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation delay={200} animateIn='fadeIn'>
                    <div className="icon_box">
                        <img src="images/positivo-icon.svg" alt="" />
                        {iniciarCounter && <h3><CountUp end={99} /> %</h3>}
                        <p>Satisfacción Usuarios</p>
                    </div>
                </ScrollAnimation>
            </section>
            :  <section id="seccion_3" className="back_3"></section>}
            <div className="scroll" id='scroll-element' style={{"display": isFixedElementVisible ? "block": "none"}}></div>
            <section id="seccion_4" className="back_1">
                <ScrollAnimation delay={200} animateIn='fadeIn'>
                    <h1 className="text_2 text-center quienes-hacemos"><FormattedMessage id="about_us_title"/></h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                            <p><FormattedMessage id="who_we_do"/></p>
                </ScrollAnimation>
                {lang === 'es_AR' ? <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className="face">
                        <img src={urlS3 + imgCeo} className="img-fluid" alt="" />
                        <h2>Miguel</h2>
                        <p>Responsable General (24 x 7 te atiende en el <a href={`https://wa.me/${telefonoComercial}`} className="phone-link">{telefonoComercial}</a>)</p>
                    </div>
                </ScrollAnimation>
                :
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className="face">
                        <img src={urlS3 + imgCeo} className="img-fluid" alt="" />
                        <h2>Alejandro</h2>
                        <p>Responsable General España<a href={`mailto:${emailComercial}`} className="email-link">{emailComercial}</a> Mov: <a href={`https://wa.me/${telefonoComercial}`} className="phone-link">{telefonoComercial}</a></p>
                    </div>
                </ScrollAnimation>
                }
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className="face">
                        <img src={urlS3 + imgContable} className="img-fluid" alt="" />
                        <h2>Verónica</h2>
                        <p>Responsable Financiera</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className="face">
                        <img src={urlS3 + imgInfra} className="img-fluid" alt="" />
                        <h2>Marcelo</h2>
                        <p>Responsable de Infraestructura Informática</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className="face">
                        <img src={urlS3 + imgHD} className="img-fluid" alt='Rocío' />
                        <h4 className='text_2'>Rocío</h4>
                        <p>Responsable Mesa de Ayuda a Productores y Salas</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation  animateOut='fadeOut' animateIn='fadeIn'>
                    <div className="face">
                        <img src={urlS3 + imgHB} className="img-fluid" alt='Yasmina' />
                        <h4 className='text_2'>Yasmina</h4>
                        <p>Responsable de Ayuda a Compradores</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation  animateOut='fadeOut' animateIn='fadeIn'>
                    <div className="face">
                        <img src={urlS3 + imgCA} className="img-fluid" alt='Ornella' />
                        <h4 className='text_2'>Ornella</h4>
                        <p>Responsable de Control de Acceso</p>
                    </div>
                </ScrollAnimation>
            </section>

        </Col>
    );
};

export default ProductoresYSalas;